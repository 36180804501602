<script lang="ts">
import { ArrowLeftIcon } from '@heroicons/vue/20/solid'

export const MULTISTEP_PAGE_EVENT_SUBMIT = 'submit' as const
export const MULTISTEP_PAGE_EVENT_CANCEL = 'cancel' as const

export interface UiDialogPageProps {
  title: string
  submitButtonText: string
  isSubmitDisabled?: boolean
  cancelButtonText?: string | null
  isLoading?: boolean
  hasBackIcon?: boolean
}

export type UiDialogPageEmits = {
  submit: []
  cancel: []
}

interface Slots {
  default(): unknown
}
</script>

<script lang="ts" setup>
const {
  isSubmitDisabled = false,
  cancelButtonText = null,
  hasBackIcon = false,
} = defineProps<UiDialogPageProps>()
const emit = defineEmits<UiDialogPageEmits>()
defineSlots<Slots>()

function submit(): void {
  emit(MULTISTEP_PAGE_EVENT_SUBMIT)
}

function cancel(): void {
  emit(MULTISTEP_PAGE_EVENT_CANCEL)
}
</script>

<template>
  <section class="relative flex flex-col gap-6">
    <header>
      <h3 class="text-lg font-bold">{{ title }}</h3>
    </header>

    <slot />

    <footer class="grid grid-cols-[auto_1fr] gap-2">
      <button class="btn btn-outline bg-base-100" @click="cancel">
        <ArrowLeftIcon v-if="hasBackIcon" class="h-4" />

        {{ cancelButtonText }}
      </button>
      <button class="btn btn-primary dark:btn-success" :disabled="isSubmitDisabled" @click="submit">
        {{ submitButtonText }}
      </button>
    </footer>

    <div
      v-if="isLoading"
      class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-base-100 bg-opacity-60"
    >
      <div class="loading loading-spinner" />
    </div>
  </section>
</template>
