import i18n from '@/i18n'

const Trans = {
  get defaultLocale(): string {
    return import.meta.env.VITE_DEFAULT_LOCALE
  },

  get currentLocale(): string {
    return i18n.global.locale.value
  },

  set currentLocale(newLocale) {
    i18n.global.locale.value = newLocale
  },

  switchLanguage(newLocale: string): void {
    Trans.currentLocale = newLocale
    document.querySelector('html')?.setAttribute('lang', newLocale)
    localStorage.setItem('user-locale', newLocale)
  },

  isLocaleSupported(locale: string | null): boolean {
    return i18n.global.availableLocales.includes(locale)
  },

  getUserLocale(): { locale: string; localeNoRegion: string } {
    const locale = window.navigator.language || Trans.defaultLocale

    return {
      locale: locale,
      localeNoRegion: locale.split('-')[0],
    }
  },

  getPersistedLocale(): string | null {
    const persistedLocale = localStorage.getItem('user-locale')

    return Trans.isLocaleSupported(persistedLocale) ? persistedLocale : null
  },

  /**
   * Guesses the default locale based on the user's persisted locale, user's preferred locale, and fallback locale.
   */
  guessDefaultLocale(): string {
    const userPersistedLocale = Trans.getPersistedLocale()

    if (userPersistedLocale) {
      return userPersistedLocale
    }

    const userPreferredLocale = Trans.getUserLocale()

    if (Trans.isLocaleSupported(userPreferredLocale.locale)) {
      return userPreferredLocale.locale
    }

    if (Trans.isLocaleSupported(userPreferredLocale.localeNoRegion)) {
      return userPreferredLocale.localeNoRegion
    }

    return i18n.global.fallbackLocale.value
  },
}

export default Trans
