import {
  ApplicationInsights,
  IExceptionTelemetry,
  IPageViewTelemetry,
} from '@microsoft/applicationinsights-web'

import { analyticsAreEnabled } from './helpers'
import { isAnalyticsInitialized, useAppInsights } from './init'

/**
 * Be careful when changing event or property names. Our analytics are based on these values.
 *
 * @param eventName - Must be **unique** and in **Title Case** with spaces between words. Action in past tense.
 * @param properties - Optional properties to attach to the event. **PascalCase**.
 * @returns
 */
export function track<TProps extends Record<string, unknown> | undefined>(
  eventName: string,
  properties?: TProps
): void {
  trackWrapper(appInsights => {
    validateEventName(eventName)
    validateAllPropNames(properties)

    appInsights.trackEvent({ name: eventName, properties })
  })
}

export function trackError(err: Error): void {
  trackWrapper(appInsights => {
    const exception: IExceptionTelemetry = { exception: err }
    appInsights.trackException(exception)
  })
}

export function trackTrace(message: string): void {
  trackWrapper(appInsights => {
    appInsights.trackTrace({ message })
  })
}

export function trackPageView<TProps extends Record<string, unknown>>(
  name: string,
  fullPath: string,
  properties?: TProps
): void {
  trackWrapper(appInsights => {
    const pageView: IPageViewTelemetry = {
      name: (name as string) ?? fullPath,
      uri: fullPath,
      properties,
    }
    appInsights.trackPageView(pageView)
  })
}

function trackWrapper(fn: (appInsights: ApplicationInsights) => void): void {
  if (!analyticsAreEnabled()) return
  if (!isAnalyticsInitialized()) {
    throw new Error('Need to initialize analytics before tracking events')
  }
  try {
    const appInsights = useAppInsights()
    fn(appInsights)
  } catch (err) {
    console.error(err)
  }
}

function validateEventName(eventName: string): void {
  const titleCaseRegex = /[A-Z][a-z]*(\s[A-Z][a-z]*)*/
  // Optional prefix in title case followed by title case
  // Valid examples: 'Logged In', 'Auth: Logged In'
  // Invalid examples: 'LoggedIn', 'Auth: LoggedIn', 'logged in', 'Logged_In'
  const regex = new RegExp(`^(${titleCaseRegex.source}: )?${titleCaseRegex.source}$`)
  const isValid = regex.test(eventName)
  if (!isValid) {
    const msg = `Cancelled analytics event, invalid event name: "${eventName}". Must be in Title Case with spaces between words.`
    throw new Error(msg)
  }
}

function validateAllPropNames(properties?: Record<string, unknown>): void {
  if (properties == null) return
  for (const propName in properties) {
    validatePropName(propName)
  }
}

function validatePropName(propName: string): void {
  const pascalCaseRegex = /^([A-Z][a-z]*)*$/
  const isValid = pascalCaseRegex.test(propName)
  if (!isValid) {
    const msg = `Cancelled analytics event, invalid property name: "${propName}". Must be in PascalCase.`
    throw new Error(msg)
  }
}
