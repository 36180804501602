<script>
import { computed } from 'vue'
import { mapGetters } from 'vuex'

import { isAdminKey, isDemoKey, isEdgeKey, isInvoicingKey } from '@/App.keys'
import { FeatConfirm } from '@/feature/feat-confirm'
import Tr from '@/i18n/translation'
import { UiEnvBanner } from '@/ui/ui-env-banner'
import { getBackendEnvName, getFirebaseEnvName } from '@/utils/env'
import { getLocalStorageItem, setLocalStorageItem } from '@/utils/local-storage'

import { db } from './plugins/db'

const { VITE_ENVIRONMENT_NAME, VITE_FAVICON_URL } = import.meta.env

export default {
  components: { UiEnvBanner, FeatConfirm },
  provide() {
    return {
      [isAdminKey]: computed(() => this.isAdminFlag),
      [isEdgeKey]: computed(() => this.isEdgeFlag),
      [isDemoKey]: computed(() => this.isDemoFlag),
      [isInvoicingKey]: computed(() => this.isInvoicingFlag),
    }
  },
  data() {
    return {
      facilityAdmin: {},
      invoiceUsers: {},
      demo: {},
      edge: {},
      version: {},
    }
  },
  firestore: {
    facilityAdmin: db.collection('settings').doc('facilityAdmin'),
    invoiceUsers: db.collection('settings').doc('invoicing'),
    demo: db.collection('settings').doc('demo'),
    edge: db.collection('settings').doc('edge'),
    version: db.collection('settings').doc('version'),
  },
  computed: {
    ...mapGetters(['username']),
    env() {
      return {
        frontendEnv: VITE_ENVIRONMENT_NAME,
        backendEnv: getBackendEnvName(),
        firebaseEnv: getFirebaseEnvName(),
      }
    },
    isEdgeFlag() {
      if (this.username) return this.username in this.edge
      return false
    },
    isAdminFlag() {
      if (this.username) return this.username in this.facilityAdmin
      return false
    },
    isDemoFlag() {
      if (this.username) return this.username in this.demo
      return false
    },
    isInvoicingFlag() {
      if (this.username) return this.username in this.invoiceUsers
      return false
    },
    updateExists() {
      const last = getLocalStorageItem('lastVersion')

      if (this.version?.fv) {
        if (last && last < this.version.fv) {
          return true
        } else {
          setLocalStorageItem('lastVersion', this.version.fv)
        }
      }

      return false
    },
  },
  mounted() {
    Tr.switchLanguage(Tr.guessDefaultLocale())
    if (!VITE_FAVICON_URL) {
      console.error('VITE_FAVICON_URL is not set in .env.local file')
      return
    }
    const linkEl = document.createElement('link')
    linkEl.setAttribute('rel', 'icon')
    linkEl.setAttribute('href', VITE_FAVICON_URL)
    document.head.appendChild(linkEl)
  },
  methods: {
    refreshApp() {
      setLocalStorageItem('lastVersion', this.version?.fv)
      window.location.reload()
    },
  },
}
</script>
<template>
  <UiEnvBanner v-bind="env" />
  <div v-if="updateExists" class="absolute z-30 flex w-full flex-col items-center bg-base-100 p-2">
    <button class="btn btn-primary btn-lg" @click="refreshApp">
      {{ $t('general.new-version') }}
    </button>
  </div>
  <RouterView />
  <FeatConfirm />
</template>
