export const ROUTES = {
  HOME: 'home',
  OVERVIEW: 'overview',
  PLOT_DUMPED: 'dumped',
  PLOT: 'plot',
  PLOT_ORDER: 'plotOrder', // currently not a proper vue route, but tracked in analytics as if it was one
  SUMMARIES: 'summaries',
  SUMMARY: 'summary',
  LASTMILE: 'lastmile',
  TL_OVERVIEW: 'avvikelse',
  KPI: 'kpi',
  PULSE: 'pulse',
  CONVERT: 'convertToParked',
  SETTINGS: 'settings',
  SIGN_IN: 'sign-in',
  ERROR: 'error',
  AUTH_MSAL: 'authMsal',
  AUTH_HANDLER: 'authHandler',
  AUTH_IFRAME: 'authIframe',
  DEV: 'dev',
}
