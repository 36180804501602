import { DotNotation, WithDefined } from '@/types/type-helpers'

/**
 * Like Object.keys(), but return type are the typed keys instead of `string[]`.
 * Should only be used when you know that no additional keys were added to `TObj`
 */
export function getTypedKeys<TObj extends Readonly<object>>(obj: TObj): (keyof TObj)[] {
  return Object.keys(obj) as Array<keyof typeof obj>
}

/**
 * Verifies that a key is defined on an object,
 * and narrows the type of the key to be a key of the object.
 */
export function hasKey<TObj extends object>(
  obj: TObj,
  key: string | number | symbol
): key is Exclude<keyof TObj, symbol | number> {
  return key in obj
}

/**
 * Verifies that a property does have a value,
 * and removes `undefined` and `null` from the type.
 */
export function propertyIsDefined<
  TObj extends object,
  TKey extends Exclude<keyof TObj, symbol | number>,
>(obj: TObj | null | undefined, key: TKey): obj is WithDefined<TObj, TKey> {
  if (obj == null) return false
  return obj[key] != null
}

/**
 * Verifies that a set of properties all have a value,
 * and removes `undefined` and `null` from the type.
 */
export function propertiesAreDefined<
  TObj extends object,
  TKeys extends Exclude<keyof TObj, symbol | number>,
>(obj: TObj, keys: TKeys[]): obj is WithDefined<TObj, TKeys> {
  return keys.every(key => obj[key] != null)
}

export function toDotNotation<T extends object>(
  obj: T,
  parentKey = ''
): Record<DotNotation<T>, unknown> {
  return getTypedKeys(obj).reduce(
    (result, key) => {
      const value = obj[key]
      const newKey = parentKey ? `${parentKey}.${String(key)}` : String(key)
      if (typeof value === 'object' && value != null) {
        return { ...result, ...toDotNotation(value, newKey) }
      }
      return { ...result, [newKey]: value }
    },
    {} as Record<DotNotation<T>, unknown>
  )
}
