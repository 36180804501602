<script lang="ts" setup>
import { computed, ref, useTemplateRef, watch } from 'vue'

import { useVModel } from '@/composables/vue'
import { destructurePromise } from '@/utils/promises'

export type AutofocusElements = 'firstButton' | 'noFocus' | 'default'

export interface UiDialogProps {
  isOpen: boolean
  autofocusElement?: AutofocusElements
}

export type UiDialogEmits = {
  'update:isOpen': [value: boolean]
  /** Emits after dialog closing animation is fully done */
  didClose: []
  clickOutside: []
}

interface Slots {
  default(slotProps: SlotProps): unknown
}

interface SlotProps {
  closeAndWaitTillAnimated: () => Promise<void>
}

const props = withDefaults(defineProps<UiDialogProps>(), {
  autofocusElement: 'default',
})

const emit = defineEmits<UiDialogEmits>()
defineSlots<Slots>()
const slotProps = computed((): SlotProps => ({ closeAndWaitTillAnimated }))

const modalRef = useTemplateRef('modal')

// We need to remove the dialog from the DOM after it's closed.
// Otherwise if there are multiple dialogs on the page, strange bugs can happen,
// like changes done in one leaking into another.
const shouldRender = ref(props.isOpen)

const isOpenModel = useVModel(props, 'isOpen', emit)

watch(
  () => [isOpenModel.value, modalRef.value],
  () => {
    // needs to come first so that modalRef is set
    if (isOpenModel.value) shouldRender.value = true
    if (!modalRef.value) return
    if (isOpenModel.value) {
      shouldRender.value = true
      modalRef.value.showModal()
      destructuredClosingAnimationPromise = makeDestructuredPromise() // reset promise

      if (props.autofocusElement === 'firstButton') {
        const firstButton = modalRef.value?.querySelector('button')
        firstButton?.focus()
      }
    } else {
      modalRef.value?.close()
    }
  },
  { immediate: true, deep: true }
)

let destructuredClosingAnimationPromise = makeDestructuredPromise()

function makeDestructuredPromise() {
  const [promise, resolve] = destructurePromise()
  // eslint-disable-next-line vue/no-async-in-computed-properties
  promise.then(() => {
    shouldRender.value = false
  })
  return [promise, resolve] as const
}

function onTransitionEnd(event: Event): void {
  if (event.target !== modalRef.value) return // Ignore events from children
  if (isOpenModel.value) return // Ignore events when opening
  emit('didClose')

  const [, resolve] = destructuredClosingAnimationPromise
  resolve()
}

/** Meant to be used in scoped slot. Will wait for closing animation to finish, if there is any. */
function closeAndWaitTillAnimated(): Promise<void> {
  isOpenModel.value = false
  const [promise] = destructuredClosingAnimationPromise
  return promise
}

function onClickOutside(): void {
  emit('clickOutside')
}
</script>

<template>
  <dialog
    v-if="shouldRender"
    ref="modal"
    class="modal"
    @cancel.prevent="
      isOpenModel = false /** Circumvent default closing of dialog when ESC is clicked */
    "
    @transitionend="onTransitionEnd"
  >
    <div class="modal-backdrop" @click.prevent="onClickOutside" />
    <div class="modal-box">
      <template v-if="props.autofocusElement === 'noFocus'">
        <!-- hidden focus trap. Can not have `display: none`, or it won't get focus in hosted environments -->
        <button autofocus class="h-0 w-0 opacity-0" />
      </template>
      <slot v-bind="slotProps" />
    </div>
  </dialog>
</template>
