<script>
import { mapGetters } from 'vuex'

import _clone from 'lodash/clone'

import Avatar from '@/components/avatar.vue'
import HeadwayWidget from '@/components/headway-widget.vue'
import Notifications from '@/components/notifications.vue'
import { useAuth } from '@/composables/auth'
import { db } from '@/plugins/db'
import { ROUTES } from '@/router/constants'

export default {
  components: { Avatar, HeadwayWidget, Notifications },
  data() {
    return {
      ROUTES,
      facilityAdmin: {},
      edge: {},
    }
  },
  firestore: {
    edge: db.collection('settings').doc('edge'),
    facilityAdmin: db.collection('settings').doc('facilityAdmin'),
  },
  computed: {
    ...mapGetters(['loggedIn', 'username', 'formatUsername']),
    historyLength() {
      return _clone(window.history.length)
    },
    isAdmin() {
      if (Object.keys(this.facilityAdmin).length > 0) return this.username in this.facilityAdmin
      return false
    },
    isEdge() {
      if (Object.keys(this.edge).length > 0) return this.username in this.edge
      return false
    },
    isDevPageVisible() {
      const env = import.meta.env.VITE_ENVIRONMENT_NAME
      return env === 'local' || env === 'test'
    },
  },
  methods: {
    signOut() {
      useAuth().logout()
    },
  },
}
</script>

<template>
  <div class="navbar mb-2 bg-neutral text-neutral-content shadow-lg">
    <button
      v-if="$route.name !== 'home' && historyLength > 1"
      class="btn btn-square btn-ghost"
      tabindex="0"
      @click="$router.back()"
    >
      <svg
        class="inline-block h-6 w-6 stroke-current text-success"
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 19l-7-7m0 0l7-7m-7 7h18"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </svg>
    </button>
    <div class="mx-2 flex-1 px-2">
      <span class="font-mon text-lg font-bold leading-none">
        {{ $store.state.title }}
      </span>
    </div>
    <div class="flex items-stretch gap-1 sm:gap-3">
      <Notifications />
      <HeadwayWidget id="changelog" account="JnQDO7"
        ><div class="flex items-center" />
      </HeadwayWidget>
      <div class="dropdown dropdown-end">
        <div class="btn btn-circle btn-ghost p-0" role="button" tabindex="0">
          <Avatar
            class="h-10 w-10"
            :name="
              formatUsername ??
              // Needs some content so that the avatar is always visible.
              // Important so that users can always find the menu options.
              '?'
            "
          />
        </div>
        <ul
          class="menu dropdown-content z-[1] min-w-40 rounded-box border bg-base-100 text-base-content shadow"
          tabindex="0"
        >
          <li v-if="isDevPageVisible">
            <RouterLink :to="{ name: ROUTES.DEV }"> Dev page </RouterLink>
          </li>
          <li>
            <a href="mailto:fv@sortera.se"> {{ $t('nav-menu.create-ticket') }} </a>
          </li>
          <li v-if="isAdmin || isEdge">
            <RouterLink :to="{ name: 'convertToParked' }">
              {{ $t('nav-menu.convert-dumped') }}
            </RouterLink>
          </li>
          <li>
            <RouterLink class="whitespace-nowrap" :to="{ name: ROUTES.SETTINGS }">
              {{ $t('nav-menu.settings') }}
            </RouterLink>
          </li>
          <li>
            <a class="flex items-center justify-between gap-2" @click="signOut">
              {{ $t('auth.signout') }}
              <svg
                class="inline-block h-5 w-5 stroke-current"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                  fill-rule="evenodd"
                />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
