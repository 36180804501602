import { ref } from 'vue'

import { Authentication, type User } from '@sortera/firebase-auth'

import i18n from '@/i18n'
import { firebaseApp } from '@/plugins/db.js'
import { trackError } from '@/utils/analytics'
import { trackTrace } from '@/utils/analytics/track.js'
import { resetFailedRequestsCount } from '@/utils/auth-state'

let auth: Authentication | null = null

const account = ref<User | null>(null)

function createOrGetAuthInstance() {
  if (auth != null) return auth
  return new Authentication(
    {
      clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
      redirectUri: window.location.origin + '/autentisering/logga-in',
      silentRedirectUri: window.location.origin + '/silent_auth.html',
      authority: `https://login.microsoftonline.com/${import.meta.env.VITE_AUTH_TENANT_ID}`,
      scopes: [import.meta.env.VITE_AUTH_GATEWAY_SCOPE],
      tenantId: import.meta.env.VITE_AUTH_TENANT_ID,
      onError: err => {
        console.error(err)
        trackError(err)
      },
      logInformation(message) {
        trackTrace(message)
      },
    },
    firebaseApp
  )
}

/**
 * Ensures authentication with firebase and MSAL.
 * Must always be called before any other method, besides `login` and `handleMsRedirect`.
 * See auth package for details
 */
async function ensureAuthenticatedUser() {
  if (account.value != null) {
    return
  }

  auth = createOrGetAuthInstance()
  try {
    await auth.initialize()
  } catch (err) {
    alert(i18n.global.t('auth.failed-init'))
  }
  account.value = await auth.getUser()
}

/**
 * Must only be called on the MSAL redirect page. `ensureAuthenticatedUser` must NOT be called before this.
 * See auth package for details.
 */
function handleMsRedirect(): Promise<void> {
  auth = createOrGetAuthInstance()
  return auth.handleMsRedirect()
}

async function login(): Promise<void> {
  auth = createOrGetAuthInstance()
  trackTrace('login attempt')
  await auth.login()
}

async function logout(): Promise<void> {
  auth = createOrGetAuthInstance()
  account.value = null
  // failed request count is reset upon successful axios request, but not all pages make requests to the gateway
  resetFailedRequestsCount()
  trackTrace('logout attempt')
  await auth.logout()
}

async function getAccessToken(): Promise<string> {
  auth = createOrGetAuthInstance()
  return await auth.getAccessToken()
}

export function useAuth() {
  return {
    account,
    ensureAuthenticatedUser,
    login,
    logout,
    getAccessToken,
    handleMsRedirect,
  }
}
