import { createI18n } from 'vue-i18n'

import enJson from './locales/en.json'
import svJson from './locales/sv.json'

// Historically, English number formats have implicitly been used throughout the app.
// Specifically we show numbers with decimal points (100.00) even when the UI language is Swedish.
// vue-i18n naturally uses the correct number format for each locale.
// In order to keep number display consistent between explicitly formatted and unformatted numbers,
// we need to override locale whenever we format numbers.
export const NUMBER_FORMAT_LOCALE = 'en' as const

const MAX_DECIMALS_WEIGHT = 2
export const WEIGHT_FORMAT = 'weight-format' as const

export default createI18n({
  locale: import.meta.env.VITE_DEFAULT_LOCALE,
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
  legacy: false,
  globalInjection: true,
  messages: {
    en: enJson,
    sv: svJson,
  },
  numberFormats: {
    en: {
      [WEIGHT_FORMAT]: {
        style: 'decimal',
        maximumFractionDigits: MAX_DECIMALS_WEIGHT,
      },
    },
    sv: {
      [WEIGHT_FORMAT]: {
        style: 'decimal',
        maximumFractionDigits: MAX_DECIMALS_WEIGHT,
      },
    },
  },
})
