import { createStore, Store } from 'vuex'

import { DbVehicleStation } from '@sortera/dvfv-types'

import { useAuth } from '@/composables/auth'
import i18n from '@/i18n'
import { getTypedKeys } from '@/utils/object'

export type State = {
  title: string
  currentStation: DbVehicleStation | null
  lmBatches: never[]
}

const store = createStore<State>({
  strict: process.env.NODE_ENV !== 'production',
  state: () => ({
    title: 'Anläggning',
    currentStation: null,
    lmBatches: [],
  }),
  getters: {
    loggedIn(): boolean {
      return useAuth().account.value != null
    },
    username(): string | undefined {
      return useAuth().account.value?.email.toLowerCase()
    },
    formatUsername(): string | undefined {
      return useAuth().account.value?.name
    },
    currentStationName(state): string | null | undefined {
      return state.currentStation?.name
    },
  },
  mutations: {
    SET_STATE(state: State, payload: State) {
      getTypedKeys(payload).forEach(key => {
        if (key in state) {
          // @ts-expect-error - we know that the keys match in both objects
          state[key] = payload[key]
        }
      })
    },
    SET_TITLE(state: State, payload: string) {
      state.title = payload

      const { VITE_ENVIRONMENT_NAME } = import.meta.env
      const defaultTitle = `${i18n.global.t('general.app-name')} ${VITE_ENVIRONMENT_NAME === 'production' ? '' : ` (${VITE_ENVIRONMENT_NAME})`}`
      document.title = payload ? payload + ` | ${defaultTitle}` : defaultTitle
    },
  },
})

export default store

export function useStore(): Store<State> {
  return store
}
